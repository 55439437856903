.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  padding-top: 3rem;
  padding-bottom: 1rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.SMALLSECONDHEADER{
font-size: 2vh;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-color: red;
}

/* This sets the entire paragraph smaller, and not underlined or red. */
.botStatus p {
  font-size: 2vh;   /* or pick any size you like */
  color: white;     /* text color for the label "Bot Status:" */
  margin: 0.5rem 0;
  background-color: transparent;
  text-decoration: none;
}

/* This styles the actual "Online" text in bright green. */
.onlineText {
  color: lime;      /* bright green */
  font-weight: bold;
}


.LIST{
  font-size: 3vh;
}

.icon{
  min-height: 2vh;
  width: 3vh;
  height: 3vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  font-size: 2vh;
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}


.notification {
  background: #2D2A2B;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#494852), to(#2A2A30));
  background-image: -webkit-linear-gradient(#494852, #2A2A30);
  background-image: -moz-linear-gradient(#494852, #2A2A30);
  background-image: -o-linear-gradient(#494852, #2A2A30);
  background-image: linear-gradient(#494852, #2A2A30);
  box-shadow: inset 0 1px 0px rgba(255, 255, 225, 0.25),0 1px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0 1px 0px rgba(255,255,225,0.25),0 1px 2px rgba(0,0,0,0.25);
  -webkit-box-shadow: inset 0 1px 0px rgba(255, 255, 225, 0.25),0 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border: 1px solid #231F20;
  width: 100px;
  height: 100px;
  position: relative;
}
.metal {
  width: 70%;
  height: 70%;
  margin: 15%;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  box-sizing: border-box;
  background: #417d9f;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#43637c), to(#2c3c4b));
  background-image: -webkit-linear-gradient(#43637c, #2c3c4b);
  background-image: -moz-linear-gradient(#43637c, #2c3c4b);
  background-image: -o-linear-gradient(#43637c, #2c3c4b);
  background-image: linear-gradient(#43637c, #2c3c4b);
  border-top: 2px solid #b8ccd7;
  padding: 5px;
  box-shadow: 0 2px 0 #21262c, 0 4px 0 #232429, 0 6px 0 #040003, 0 0 20px #000, 0 5px 20px #000, 0 -2px 0 #6d93a6;
  -moz-box-shadow: 0 2px 0 #21262c, 0 4px 0 #232429, 0 6px 0 #040003, 0 0 20px #000, 0 5px 20px #000, 0 -2px 0 #6d93a6;
  -webkit-box-shadow: 0 2px 0 #21262c, 0 4px 0 #232429, 0 6px 0 #040003, 0 0 20px #000, 0 5px 20px #000, 0 -2px 0 #6d93a6;
}
.light {
  position: relative;
   border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 3px 0px #2e4452, inset 0 5px 0 #392c33;
  -moz-box-shadow: inset 0 3px 0px #2e4452, inset 0 5px 0 #392c33;
  -webkit-box-shadow: inset 0 3px 0px #2e4452, inset 0 5px 0 #392c33;
  background: #ffe913; 
  background: #920000; /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, #920000 39%, #2d0408 67%, #000000 71%); /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(39%,#920000), color-stop(67%,#2d0408), color-stop(71%,#000000)); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, #920000 39%,#2d0408 67%,#000000 71%); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover, #920000 39%,#2d0408 67%,#000000 71%); /* Opera 12+ */
  background: -ms-radial-gradient(center, ellipse cover, #920000 39%,#2d0408 67%,#000000 71%); /* IE10+ */
  background: radial-gradient(ellipse at center, #920000 39%,#2d0408 67%,#000000 71%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#920000', endColorstr='#000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
   
}

.glowing-red {
  position: absolute;
  background: #F11A08;  
  width: 45%;
  height: 45%;
  margin:28%;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  box-shadow: 0 0 15px #F11A08;
  -moz-box-shadow: 0 0 15px #F11A08;
  -webkit-box-shadow: 0 0 15px #F11A08;
}

.glowing-yellow {
  background: #FFE913; 
  width: 50%;
  height: 50%;
  margin:24%;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  box-shadow: 0 0 5px #FFE913;
  -moz-box-shadow: 0 0 5px #FFE913;
  -webkit-box-shadow: 0 0 5px #FFE913;
}

/* Defining Button Active State */
.notification.active .light{
  /* Adding animation to Hal9000 */
  animation: blink-red-shadow 2s infinite ease-in-out;
  -moz-animation: blink-red-shadow  2s infinite ease-in-out;
  -o-animation: blink-red-shadow  2s infinite ease-in-out;
  -webkit-animation: blink-red-shadow  2s infinite ease-in-out;
}
.notification.active .light .glowing-red{
  /* Adding animation to Hal9000 */
  animation: blink-red 2s infinite ease-in-out;
  -moz-animation: blink-red  2s infinite ease-in-out;
  -o-animation: blink-red  2s infinite ease-in-out;
  -webkit-animation: blink-red  2s infinite ease-in-out;
}
.notification.active .light .glowing-yellow{
  /* Adding animation to Hal9000 */
  animation: blink-yellow 2s infinite ease-in-out;
  -moz-animation: blink-yellow  2s infinite ease-in-out;
  -o-animation: blink-yellow  2s infinite ease-in-out;
  -webkit-animation: blink-yellow  2s infinite ease-in-out;
}

/* Defining Hal9000 blinking animation  */
@-webkit-keyframes blink-red  {
from {
  box-shadow: 0 0 5px #F11A08;
  -moz-box-shadow: 0 0 5px #F11A08;
  -webkit-box-shadow: 0 0 5px #F11A08;
  width: 45%;
  height: 45%;
}
50% {
  box-shadow: 0 0 25px #ff6b5f;
  -moz-box-shadow: 0 0 25px #ff6b5f;
  -webkit-box-shadow: 0 0 25px #ff6b5f;
  width: 70%;
  height: 70%;
  margin:17%;
}
to {
  box-shadow: 0 0 5px #F11A08;
  -moz-box-shadow: 0 0 5px #F11A08;
  -webkit-box-shadow: 0 0 5px #F11A08;
  width: 45%;
  height: 45%;
}
}

@-webkit-keyframes blink-red-shadow  {
  from {
  box-shadow: inset 0 3px 0px #2e4452, inset 0 5px 0 #392c33;
  -moz-box-shadow: inset 0 3px 0px #2e4452, inset 0 5px 0 #392c33;
  -webkit-box-shadow: inset 0 3px 0px #2e4452, inset 0 5px 0 #392c33;
}
50% {
  box-shadow: inset 0 3px 0px #52352E, inset 0 5px 0 #312A25;
  -moz-box-shadow: inset 0 3px 0px #52352E, inset 0 5px 0 #312A25;
  -webkit-box-shadow: inset 0 3px 0px #52352E, inset 0 5px 0 #312A25;
}
to {
  box-shadow: inset 0 3px 0px #2e4452, inset 0 5px 0 #392c33;
  -moz-box-shadow: inset 0 3px 0px #2e4452, inset 0 5px 0 #392c33;
  -webkit-box-shadow: inset 0 3px 0px #2e4452, inset 0 5px 0 #392c33;
}
}

@-webkit-keyframes blink-yellow  {
from {
  box-shadow: 0 0 5px #ffeb1c;
  -moz-box-shadow: 0 0 5px #ffeb1c;
  -webkit-box-shadow: 0 0 5px #ffeb1c;
}
50% {
  box-shadow: 0 0 25px #fff;
  -moz-box-shadow: 0 0 25px #fff;
  -webkit-box-shadow: 0 0 25px #fff;
}
to {
  box-shadow: 0 0 5px #ffeb1c;
  -moz-box-shadow: 0 0 5px #ffeb1c;
  -webkit-box-shadow: 0 0 5px #ffeb1c;
}
}

/* Interactive Effect on Hover */
.notification.active {
  cursor: pointer;
}

.notification.active .hover-effect {
  -webkit-transition-duration:0.5s;
  -moz-transition-duration:0.5s;
  -ms-transition-duration:0.5s;
  -o-transition-duration:0.5s;
  transition-duration:0.5s;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  box-sizing: border-box; 
  border:15px solid rgba(184, 204, 215, 0); 
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.notification:hover  .hover-effect {
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border:0 solid rgba(184, 204, 215, 0.5)
}


h2 {
  font-size:40px;
  display:inline-block;
  padding:10px;
  color:white;
  border-radius:8px;
  background-color:red;
}

h3 {
  font-size:20px;
  display:inline-block;
  padding:10px;
  color:black;
  border-radius:16px;
  background-color:white;
}
